import React, { useState, useEffect, useRef, useMemo } from 'react'
import styles from './Rocket.module.scss'
import Submarine from '../../assets/image/submarine.png'
import Missile from '../../assets/image/missile.png'
import Explosion from '../../assets/explosion.mp4'
import Shadow from '../../assets/image/shadow.svg'
import Hand from '../../assets/image/hand.svg'
import Neptun from '../../assets/image/neptun.png'
import { useLocation } from 'react-router-dom'

function Rocket() {
  const [isVisible, setIsVisible] = useState(false)
  const myAnimationRef = useRef(null)
  const [showRocket, setShowRocket] = useState(true)
  const [showExplosion, setShowExplosion] = useState(false)
  const [showText, setShowText] = useState(false)
  const [showNeptun, setShowNeptun] = useState(false)
  const [hideNeptun, setHideNeptun] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const location = useLocation();

  const hasUAAnimation = useMemo(() => location.pathname === '/v', [])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.disconnect()
          }
        })
      },
      { threshold: 0.5 }
    )

    if (myAnimationRef.current) {
      observer.observe(myAnimationRef.current)
    }

    return () => {
      if (myAnimationRef.current) {
        observer.unobserve(myAnimationRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setShowExplosion(true)
        setShowRocket(false)
      }, 1900)
    }
  }, [isVisible])

  useEffect(() => {
    if (showExplosion && isVisible) {
      setTimeout(() => {
        setShowText(true)
      }, 2000)
    }
  }, [showExplosion, isVisible])

  useEffect(() => {
    if (showText && isVisible) {
      if (!hasUAAnimation) {
        return
      }

      setShowNeptun(true)
    }
  }, [showText, isVisible])

  return (
      <div className={styles.sea} ref={myAnimationRef}>
        <img className={styles.sea__submarine} src={Submarine} alt="submarine" />
        {(showRocket || windowWidth < 1100) && (
            <img
                className={`${styles.sea__missile} ${isVisible && styles.sea__missile__animated}`}
                src={Missile}
                alt="missile"
            />
        )}
        {showExplosion && windowWidth > 1100 && (
            <div className={styles.sea__container}>
              <video className={styles.sea__video} autoPlay muted>
                <source src={Explosion} type="video/mp4" />
              </video>
            </div>
        )}
        {showText && windowWidth > 1100 && (
            <img className={styles.sea__shadow} src={Shadow} alt="shadow" />
        )}
        {showText && windowWidth > 1100 && (
            <div
                className={`${styles.sea__text} ${showNeptun && isVisible && styles.sea__text__scale}`}
            >
              <button style={{cursor: 'pointer'}} className={styles.sea__text__button} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
                Join
              </button>
            </div>
        )}
        {windowWidth < 1100 && (
            <div className={styles.sea__small}>
              <button style={{cursor: 'pointer'}} className={styles.sea__small__button} onClick={() => window.open("https://t.me/ZombiBotBeta_bot/play", "_blank")}>
                Join
              </button>
            </div>
        )}
        {showNeptun && windowWidth > 1100 && (
            <img
                className={`
          ${styles.sea__neptun} 
          ${isVisible && styles.sea__neptun__animated}
          ${hideNeptun && styles.sea__neptun__animated__hide}
          `}
                src={Neptun}
                alt="neptun"
            />
        )}
      </div>
  )
}

export default Rocket
