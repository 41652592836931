import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Header.module.scss'
import TrumpImage1 from '../../assets/image/trump/1.png'
import TrumpImage2 from '../../assets/image/trump/2.png'
import TrumpImage3 from '../../assets/image/trump/3.png'
import TrumpImage4 from '../../assets/image/trump/4.png'
import TrumpImage5 from '../../assets/image/trump/5.png'
import TrumpImage6 from '../../assets/image/trump/6.png'
import TrumpImage7 from '../../assets/image/trump/7.png'
import HarrisImage1 from '../../assets/image/harris/1.png'
import HarrisImage2 from '../../assets/image/harris/2.png'
import HarrisImage3 from '../../assets/image/harris/3.png'
import HarrisImage4 from '../../assets/image/harris/4.png'
import HarrisImage5 from '../../assets/image/harris/5.png'
import HarrisImage6 from '../../assets/image/harris/6.png'
import HarrisImage7 from '../../assets/image/harris/7.png'
import Zombi1 from '../../assets/image/zombi/zombi1.png'
import Zombi2 from '../../assets/image/zombi/zombi2.png'
import Scroll from '../../assets/image/scroll.svg'
import GlitchText from '../GlitchText/GlitchText'
import {redirectWallet} from "../../constants/redirect/redirect";

const trump = [
  TrumpImage1,
  TrumpImage2,
  TrumpImage3,
  TrumpImage4,
  TrumpImage5,
  TrumpImage6,
  TrumpImage7
]
const harris = [
  HarrisImage1,
  HarrisImage2,
  HarrisImage3,
  HarrisImage4,
  HarrisImage5,
  HarrisImage6,
  HarrisImage7
]

// СТЕЙТ

function Header() {
  const [increaseVotesHarris, setIncreaseVotesHarris] = useState(false)
  const [increaseVotesTrump, setIncreaseVotesTrump] = useState(false)
  const [increaseIndexHarris, setIncreaseIndexHarris] = useState(0)
  const [increaseIndexTrump, setIncreaseIndexTrump] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [initAnimation, setInitAnimation] = useState(false)
  const [currentIndexTrump, setCurrentIndexTrump] = useState(0)
  const [currentIndexHarris, setCurrentIndexHarris] = useState(0)
  const intervalRefTrump = useRef(null)
  const intervalRefHarris = useRef(null)
  const headerRef = useRef(null)
  const startParam = btoa(redirectWallet)

  const dispatch = useDispatch()

  useEffect(() => {
    trump.forEach((imageSrc) => {
      const img = new Image()
      img.src = imageSrc
    })
    harris.forEach((imageSrc) => {
      const img = new Image()
      img.src = imageSrc
    })
  })

  const startAnimation = (party) => {
    if (party === 'trump' && intervalRefTrump.current) {
      clearInterval(intervalRefTrump.current)
      intervalRefTrump.current = null
    }

    if (party === 'harris' && intervalRefHarris.current) {
      clearInterval(intervalRefHarris.current)
      intervalRefHarris.current = null
    }

    let index = 0
    if (party === 'trump') {
      setCurrentIndexTrump(index)
    } else {
      setCurrentIndexHarris(index)
    }

    if (party === 'trump') {
      intervalRefTrump.current = setInterval(() => {
        setCurrentIndexTrump((prevIndex) => {
          index = (prevIndex + 1) % trump.length
          return index
        })

        if (index === trump.length - 1) {
          clearInterval(intervalRefTrump.current)
          intervalRefTrump.current = null
          setTimeout(() => {
            setCurrentIndexTrump(0)
          }, 60)
        }
      }, 60)
    } else {
      intervalRefHarris.current = setInterval(() => {
        setCurrentIndexHarris((prevIndex) => {
          index = (prevIndex + 1) % harris.length
          return index
        })

        if (index === harris.length - 1) {
          clearInterval(intervalRefHarris.current)
          intervalRefHarris.current = null
          setTimeout(() => {
            setCurrentIndexHarris(0)
          }, 60)
        }
      }, 60)
    }
  }

  const sendHarrisVote = () => {
    if (!isScrolled) {
      dispatch({ type: 'votes/sendPartyVote', payload: 'DEMOCRATIC' })
      startAnimation('harris')
      setIncreaseIndexHarris(increaseIndexHarris + 1)
      setIncreaseVotesHarris(true)
    }
  }

  const redirectForApp = () => {
    window.open(`${process.env.REACT_APP_BOT_URL}/play?startapp=${startParam}`, '_blank')
  }

  const redirectForPickSale = () => {
    window.open(`https://t.me/ZombiBotBeta_bot/play`, '_blank')
  }

  const sendTrumpVote = () => {
    if (!isScrolled) {
      dispatch({ type: 'votes/sendPartyVote', payload: 'REPUBLICAN' })
      startAnimation('trump')
      setIncreaseIndexTrump(increaseIndexTrump + 1)
      setIncreaseVotesTrump(true)
    }
  }

  useEffect(() => {
    if (increaseVotesHarris) {
      setTimeout(() => {
        setIncreaseVotesHarris(false)
        setIncreaseIndexHarris(0)
      }, 1000)
    }
  }, [increaseVotesHarris])

  useEffect(() => {
    if (increaseVotesTrump) {
      setTimeout(() => {
        setIncreaseVotesTrump(false)
        setIncreaseIndexTrump(0)
      }, 1000)
    }
  }, [increaseVotesTrump])

  useEffect(() => {
    dispatch({ type: 'votes/getVotes' })
    dispatch({ type: 'votes/getVotesStatistic' })
  }, [])

  const harrisImage = useMemo(() => {
    return harris[currentIndexHarris]
  }, [harris, currentIndexHarris])

  const trumpImage = useMemo(() => {
    return trump[currentIndexTrump]
  }, [trump, currentIndexTrump])
  return (
    <div
      ref={headerRef}
      className={`${styles.header}`}
    >
      <div className={styles.header__container}>
        <div className={styles.header__container__gradient}></div>
        <div
          className={`${styles.header__container__scroll} `}
        >
          <img src={Scroll} alt="scroll" />
          <div className={styles.header__container__scroll__text}>Scroll Down</div>
        </div>
        <div
          className={`${styles.header__container__main}`}
        >
          <GlitchText className={styles.header__container__main__text}>
            Join Zombi <br />
            Apocalypsis Bot
          </GlitchText>
          <button onClick={redirectForPickSale} className={styles.header__container__join}>
            Join
          </button>
        </div>
        <div className={styles.header__container__zombies}>
          <img
            src={Zombi2}
            className={styles.header__container__zombies__zombi}
            />
          <img
            src={Zombi1}
            className={styles.header__container__zombies__zombi}
            />
        </div>
        {/*<div*/}
        {/*    className={`${styles.header__container__harris}`}*/}
        {/*>*/}
        {/*  <img*/}
        {/*      onClick={sendHarrisVote}*/}
        {/*      src={harrisImage}*/}
        {/*      className={styles.header__container__harris__image}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div*/}
        {/*    className={`${styles.header__container__trump}`}*/}
        {/*>*/}
        {/*  <img*/}
        {/*      onClick={sendTrumpVote}*/}
        {/*      src={trumpImage}*/}
        {/*      className={styles.header__container__trump__image}*/}
        {/*  />*/}
        {/*  <div className={styles.header__container__trump__blur} />*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default Header
