import React, { useState, useEffect } from 'react'
import Header from '../../components/Header/Header'
import Rocket from '../../components/Rocket/Rocket'
import Journey from '../../components/Journey/Journey'
import Footer from '../../components/Footer/Footer'
import Navigation from '../../components/Navigation/Navigation'
import './Landing.module.scss'
import Roadmap from "../../components/Roadmap/Roadmap";
import Faqs from "../../components/Faqs/Faqs";

const sections = [
  { id: 'header', content: <Header /> },
  { id: 'rocket', content: <Rocket /> },
  { id: 'roadmap', content: <Roadmap /> },
  { id: 'faqs', content: <Faqs /> },
  { id: 'journey', content: <Journey /> },
  { id: 'footer', content: <Footer /> }
]

function Landing() {
  return (
    <div className="scroll-container">
      <Navigation />
      <div style={{ maxWidth: '1920px', margin: '0 auto'}} className="wrapper">
        {sections.map((section) => (
            <div
                key={section.id}
                id={section.id}
                className="section"
            >
              {section.content}
            </div>
        ))}
      </div>
    </div>
  )
}

export default Landing
